import {Paper, List, Box, Link, Stack, Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SettingDayRangeDay from './DayRangeDay';
import {useNavigate} from 'react-router-dom';

const SettingDayRange = (props) => {
    const settings = props.settings;
    const navigate = useNavigate();
    return (
        <>
        {settings !== undefined && (
            <Paper>
                <Stack direction="row">
                    <Box display="flex" justifyContent="flex-start" sx={{ width: "100%", pb:2 }}>
                        <Stack>
                            <Typography variant="h6" sx={{p:2}}>Días disponibles</Typography>
                            <List dense={true} disablePadding>
                                {settings.week.map((value, index) => <SettingDayRangeDay key={"sdrd-"+index} index={index} available={value} />)}
                            </List>
                        </Stack>
                    </Box>
                    <Box display="flex" justifyContent="flex-start">
                        <Link href="#" onClick={(event) => {
                        event.preventDefault(); navigate('edit/days', {state:{week_range: settings.week_range, uid: props.shopUID}});
                        }
                        } color="primary" sx={{p:0, mt:2, mr:2}}>
                        <EditIcon sx={{ height: 20, width: 20 }} />
                    </Link>
                </Box>
            </Stack>
        </Paper>
        )}
    </>
);
}

export default SettingDayRange;
