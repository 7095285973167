import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Paper, Stack, TextField, Button} from '@mui/material';
import {put} from './constants/api';
import Message from './Message';
import ShopSelect from './ShopSelect';
import ZoneSelect from './ZoneSelect';

const Add = () => {
    const [loaded, setLoaded] = useState(false);
    const [shopUID, setShopUID] = useState('');
    const [zoneUID, setZoneUID] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    const handleSubmit = (event) => {
        event.preventDefault();
        put(event).then((data) => {
            if(data.status === 'success') {
                navigate("/mesas/", {state:{message:data}});
            } else {
                navigate(".", {state:{message:data}});
            }
        });
    }

    return (<Container maxWidth="xs">
        <Message />
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack spacing={2}>
                <TextField
                name="name"
                required
                fullWidth
                id="name"
                label="Nombre Mesa"
                inputProps={{ maxLength: 3 }}
                autoFocus />
                <TextField type="number" name="slots" label="Capacidad en número de personas" defaultValue={4} InputLabelProps={{ shrink: true }} />
                <ShopSelect shopUID={shopUID} setShopUID={setShopUID} />
                <ZoneSelect shopUID={shopUID} zoneUID={zoneUID} setZoneUID={setZoneUID}/>

                <Stack direction="row" spacing={2}>
                    <Button onClick={() => navigate('/mesas/', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                        Cancelar
                    </Button>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                        Guardar
                    </Button>
                </Stack>
            </Stack>
        </Paper>
        </Container>);
}

export default Add;
