// CRUD 
import {endpoint} from './endpoints';

export const put = (event) => {
    const data = new FormData(event.currentTarget);
    const _zone_uid = data.get('zone_uid');
    const _shop_uid = data.get('shop_uid');
    const zone_uid = (_zone_uid === "") ? "" : parseInt(_zone_uid);
    const shop_uid = (_shop_uid === "") ? "" : parseInt(_shop_uid);

    const options = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'name': data.get('name'), 'slots': data.get('slots'), 'shop_uid': shop_uid, 'zone_uid': zone_uid})
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const get = () => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const post = (uid) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const patch = (event, uid) => {
    const data = new FormData(event.currentTarget);
    const _zone_uid = data.get('zone_uid');
    const _shop_uid = data.get('shop_uid');
    const zone_uid = (_zone_uid === "") ? "" : parseInt(_zone_uid);
    const shop_uid = (_shop_uid === "") ? "" : parseInt(_shop_uid);

    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid, name: data.get('name'), 'slots': data.get('slots'), 'shop_uid': shop_uid, zone_uid: zone_uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const remove = (uid) => {
    const options = {
        method: "DELETE",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}
