import {Grid, Paper, Box, Stack, List, ListItem, ListItemText, IconButton, Divider, Avatar} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import Share from './Share';

const IndexItem = (props) => {
    const navigate = useNavigate();
    const data = props.data;
    return (<Grid item xs={12}><Paper>
            <Stack>
                <Stack direction="row">
                    <List sx={{width:'100%'}}>
                        <ListItem>
                            <ListItemText primary="Restaurante" secondary={data.name} />
                        </ListItem>
                    </List>
                    <Box display="flex" justifyContent="center" sx={{p: 2, mr:2}}>
                        <Avatar src={data.avatar} sx={{ width:64, height:64 }} />
                    </Box>
                </Stack>
                <Box>
                    <Divider sx={{mt:2}} />
                    <Stack direction="row" spacing={2} display="flex" justifyContent="space-between" sx={{p:2}}>
                        <IconButton onClick={() => navigate('remove', {state: {uid: data.uid}})} aria-label="Borrar" size="large">
                            <DeleteIcon />
                        </IconButton>
                        <Stack direction="row">
                            <Share data={data.url}/>
                            <IconButton onClick={() => navigate('settings/', {state: {uid: data.uid}})} aria-label="Editar" size="large">
                                <SettingsIcon />
                            </IconButton>
                            <IconButton onClick={() => navigate('edit', {state: {uid: data.uid}})} aria-label="Editar" size="large">
                                <EditIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Paper>
        </Grid>);
}
export default IndexItem;
