import {endpoint} from './endpoints';

export const retrieveSettings = (shop_uid) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoint+shop_uid, options)
        .then((response) => response.json())
}

export const sendSettingsDaysRange = (shop_uid, days_range) => {
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'shop_uid': shop_uid, 'action':'days_range', 'start': days_range[0], 'end': days_range[1]})
    }
    return fetch(endpoint, options)
        .then((response) => response.json())
}

export const sendSettingsHoursRange = (shop_uid, start, end, dayOfWeek) => {
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'shop_uid': shop_uid, 'action': 'hours_range', 'start_hour': start.hour, 'start_minute': start.minute, 'end_hour': end.hour, 'end_minute': end.minute, 'day_of_week': dayOfWeek})
    }
    return fetch(endpoint, options)
        .then((response) => response.json())
}

export const getHourMinutesFormatted = (hourPair) => { 
    const padMinutes = String(hourPair.hour).padStart(2, '0')
    const padHour = String(hourPair.minute).padStart(2, '0')
    return `${padMinutes}:${padHour}`;
}

