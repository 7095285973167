import {endpoint_index, endpoint} from './endpoints';

export const retrieveBookings = (shopUID = null, page = null) => {
    var _endpoint_index = endpoint_index;
    if (shopUID !== null) {
        _endpoint_index = _endpoint_index + shopUID;
    }
    if (page !== null) {
        _endpoint_index = _endpoint_index + "?page="+page;
    }
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(_endpoint_index, options).then((response) => response.json())
}

export const patch = (uid, nulled) => {
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid, nulled: nulled})
    }
    return fetch(endpoint, options)
        .then((response) => response.json())
}
