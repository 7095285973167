import {useState, useEffect} from 'react';
import {FormControl, InputLabel, Select, MenuItem, Typography} from '@mui/material';
import {get, getZoneBySlug} from '../Zone/constants/api';
import {useParams} from 'react-router-dom';

const ZoneSelect = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [zones, setZones] = useState(undefined);
    const [parentValue, setParentValue] = useState(props.shopUID);
    const {shop_slug, zone_slug} = useParams();
    const zoneUID = props.zoneUID;
    const setZoneUID = props.setZoneUID;
    const shopUID = props.shopUID;

    const handleChange = (event) => {
        setZoneUID(event.target.value);
    };

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            if(typeof shopUID === 'number') {
                get(shopUID).then((data) => {
                    setZones(data);
                });
            }

            if(typeof shop_slug === 'string' && typeof zone_slug === 'string') {
                getZoneBySlug(shop_slug, zone_slug).then(data => setZoneUID(data.uid));
            }
        }
        if(loaded && shopUID !== parentValue) {
            setParentValue(shopUID);
            if(typeof shopUID !== 'number') {
                setZones(undefined);
            } else {
                get(shopUID).then((data) => {
                    setZones(data);
                });
            }
        }
    }, [loaded, props, parentValue, zoneUID, shopUID, shop_slug, zone_slug, setZoneUID]);

    return (<>
        {(zones !== undefined && zones !== null && zones.length > 0) ? (<FormControl fullWidth><InputLabel id="select-zone-id">Sector</InputLabel><Select id="select-zone-uid" inputProps={{name:"zone_uid"}} value={zoneUID} onChange={handleChange} label={"Sector"}>
                <MenuItem value="">
                    <em>Sin asignar</em>
                </MenuItem>
                {zones.map((zone, index) => <MenuItem key={"zs-"+index} value={zone.uid}>{zone.name}</MenuItem>)}
                </Select></FormControl>) : (
                    <Typography align="center" variant="body2" sx={{p:2}}>No hay sectores para seleccionar</Typography>
                    )}
    </>);

}

export default ZoneSelect;
