import {useState} from 'react';
import {Container, Paper, Box, Slider, Typography, Alert} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {getHourMinutesFormatted, sendSettingsHoursRange} from './constants/api';
import {weekDaysSpanish} from './constants/text';
import BackButton from './BackButton';

const step = 1/4;

const marks = [
    {
        value: 0,
        label: '00:00',
    },
    {
        value: 6,
        label: '06:00',
    },
    {
        value: 12,
        label: '12:00',
    },
    {
        value: 18,
        label: '18:00',
    },
    {
        value: 24,
        label: '24:00',
    },
];

const calculateValue = (value) => {
    return value;
}

const hourMinuteToValue = (hour, minute) => {
    var floatPart = 0;
    if (minute > 0) {
        floatPart = (0.25)*(minute / 15)
    }
    return hour + floatPart;
}

const getHourMinutesFromValue = (_value) => {
    const floatPart = _value - Math.trunc(_value);
    const hour = Math.trunc(_value);
    const minute = Math.round((floatPart / 0.25)*15);
    return {'hour': hour, 'minute': minute};
}

const getStartEndHourFromValues = (values) => {
    const start = getHourMinutesFromValue(values[0]);
    const end = getHourMinutesFromValue(values[1]);
    return {'start': start, 'end': end};
}
const labelFormat = (value) => {
    const floatPart = value - Math.trunc(value);
    const hour = Math.trunc(value);
    const minute = Math.round((floatPart / 0.25)*15);
    return getHourMinutesFormatted({'hour': hour, 'minute':minute})
}

const SettingHourRangeEdit = (props) => {
    const _location = useLocation();
    const shopUID = _location.state.uid;
    const title = props.title || 'Horario disponible';
    const defaultStart = (_location.state) ? hourMinuteToValue(_location.state?.start.hour, _location.state.start.minute) : 9;
    const defaultEnd = (_location.state) ? hourMinuteToValue(_location.state?.end.hour, _location.state.end.minute) : 19;
    const dayOfWeek = _location.state.dayOfWeek;
    const [values, setValues] = useState([defaultStart, defaultEnd]);

    const handleChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        const minDistance = 1;
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValues([Math.min(newValue[0], values[1] - minDistance), values[1]]);
        } else {
            setValues([values[0], Math.max(newValue[1], values[0] + minDistance)]);
        }
    };
    const handleChangeCommitted = (event, newValues: number[], activeThumb) => {
        const data = getStartEndHourFromValues(newValues)
        sendSettingsHoursRange(shopUID, data.start, data.end, dayOfWeek).then(() => console.log(data))
    }
    return (<Container maxWidth="xs" sx={{mt:2}}>
        <Paper sx={{p:2}}>
            <Box sx={{width:'100%'}}>
                <Typography align="center" variant="h5"sx={{mb:4}}>{title} para los días {weekDaysSpanish[dayOfWeek]}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{p:4, width: '100%'}}>
                <Slider
                getAriaLabel={() => 'Horario de funcionamiento'}
                min={0}
                max={24}
                step={step}
                scale={calculateValue}
                value={values}
                onChange={handleChange}
                onChangeCommitted={handleChangeCommitted}
                valueLabelFormat={labelFormat}
                marks={marks}
                valueLabelDisplay="on" 
                disableSwap />
            </Box>
            <Alert severity="info">El horario de disponibilidad es entre las {labelFormat(values[0])} y las {labelFormat(values[1])} horas</Alert>
        </Paper>
        <BackButton link={-1} />
        </Container>);
}

export default SettingHourRangeEdit;
