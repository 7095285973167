import {useEffect, useState} from 'react';
import {Box, Stack, Avatar, List, ListItem, ListItemText} from '@mui/material';
import BookingIndexItemDetails from './IndexItemDetails';
const BookingIndexItem = (props) => {
    const [loaded, setLoaded] = useState(false); 
    const data = props.data;
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);
    return (<>
        {loaded && (
                <Stack direction="row">
                    <Box display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{width: "50%"}}>
                    <Stack>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Avatar alt="Avatar" sx={{width: 120, height: 120, p:2}} src={data.shop_avatar} />
                        </Box>
                        <List dense={true} disablePadding={true}>
                            <ListItem>
                                <ListItemText primary="Restaurante" secondary={data.shop_name} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Sector" secondary={data.zone_name} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Mesa" secondary={data.agenda_name} />
                            </ListItem>
                        </List>
                    </Stack>
                </Box>
                <BookingIndexItemDetails data={data} />
            </Stack>
        )}
    </>);
}

export default BookingIndexItem;
