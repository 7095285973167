import {useState, useEffect} from 'react';
import {Container, Paper, Grid, Button, TextField} from '@mui/material';
import {saveEmail} from './constants/functions/email';
import {retrieveProfile} from './constants/functions/profile';
import {useNavigate} from 'react-router-dom';
import Message from './Message';

const ProfileEmailEdit = (props) => {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [item, setItem] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        saveEmail(event).then((data) => {
            if(data.status === "success" || data.status === "info") {
                navigate('/profile/', {state: {message: data}});
            } else {
                navigate('.', {state: {message: data}});
            }
        });
    }
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveProfile().then((data) => {
                setItem(data.email);
            });
        }
    }, [loaded]);
    return (<>{loaded && (
        <Container maxWidth="xs">
            <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Message />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        autoComplete="email"
                        name="email"
                        value={item}
                        onChange={(e) => setItem(e.target.value)}
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        autoFocus />
                    </Grid>
                </Grid>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, }}>
                Guardar 
            </Button>
        </Paper>
    </Container>
    )}</>);
}

export default ProfileEmailEdit;
