// CRUD 
import {endpoint, endpoint_info} from './endpoints';

export const put = (event, file) => {
    const data = new FormData(event.currentTarget);
    data.append('file', file);
    const options = {
        method: "PUT",
        credentials: 'include',
        body: data
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const get = () => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const post = (uid) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const patch = (event, uid, file) => {
    const data = new FormData(event.currentTarget);
    data.append("uid", uid)
    data.append('file', file);
    const options = {
        method: "PATCH",
        credentials: 'include',
        body: data
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const remove = (uid) => {
    const options = {
        method: "DELETE",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const info = (slug) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    };
    return fetch(endpoint_info+slug, options).then((response) => response.json());
}


