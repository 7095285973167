import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {retrieveActivationNewPhone} from './constants/api.js';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import {Container, Stack, Box, Paper, Typography} from '@mui/material';
import Loader from './Loader';

const ActivateNewPhone = () => {
    const [loaded, setLoaded] = useState(false);
    const [response, setResponse] = useState(undefined);
    const {uid} = useParams();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveActivationNewPhone(uid).then((data) => {
                setResponse(data);
            });
        }
    }, [loaded, uid]);
    return (<>
        {!loaded && (
            <Loader />
            )}
        {response !== undefined && (<Container maxWidth="xs">
            <Paper elevation={2} sx={{ p: 2, mt:2 }}>
                <Stack direction="row" sx={{p:2}}>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ minWidth: "40%" }}>
                        {response.status === "success" ? (
                        <AssignmentTurnedInIcon sx={{ fontSize: "100px" }} color="success" />
                        ) : (<AssignmentLateIcon sx={{ fontSize: "100px" }} color="error" />)}
                    </Box>
                    <Box
                    sx={{width:"100%"}}
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Typography variant="body1" align="center">{response.message}</Typography>
                </Box>
            </Stack>
        </Paper>
        </Container>)}
    </>);
}

export default ActivateNewPhone;
