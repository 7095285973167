import {endpoint} from '../endpoints';

export const savePhone = (phone) => {
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'phone': phone})
    }
    return fetch(endpoint, options)
        .then((response) => response.json());

}
