import {useState, useEffect} from 'react';
import {Paper, List, ListItem, ListItemText, Box, Link, Stack, Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from 'react-router-dom';
import {weekDaysSpanish} from './constants/text';
import {getHourMinutesFormatted} from './constants/api';

const SettingHourRange = (props) => {
    const navigate = useNavigate();
    const settings = props.settings;
    const [lastTrueIndex, setLastTrueIndex] = useState(undefined);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
        if(loaded && settings !== undefined) {
            const week = [...settings.week]; // array copy
            const lastTrueIndexReverse = week.reverse().findIndex(x => x === true);
            setLastTrueIndex(week.length - 1 - lastTrueIndexReverse);
        }
    }, [loaded, settings]);

    return (
        <>
        {settings !== undefined && (<>
            {settings.week.map((weekdayAvailable, weekdayIndex) => (<Stack key={"shr-stack-"+weekdayIndex}>{weekdayAvailable === true && (<Paper key={"shr-paper-"+weekdayIndex} sx={{ mt: 2, mb: (weekdayIndex === lastTrueIndex) ? 0 : 0 }}>
                    <Stack direction="row">
                        <Box display="flex" justifyContent="flex-start" sx={{ width: "100%", pb:2 }}>
                            <Stack>
                                <Typography variant="h6" sx={{p:2}}>Horario día {weekDaysSpanish[weekdayIndex]}</Typography>
                                <List disablePadding>
                                    <ListItem><ListItemText primary="Apertura" secondary={getHourMinutesFormatted(settings.hours[weekdayIndex].start) + " horas"} /></ListItem>
                                    <ListItem><ListItemText primary="Cierre" secondary={getHourMinutesFormatted(settings.hours[weekdayIndex].end) + " horas"} /></ListItem>
                                </List>
                            </Stack>
                        </Box>
                        <Box display="flex" justifyContent="flex-start">
                            <Link href="#" onClick={(event) => {
                            event.preventDefault();
                            navigate('edit/hours', {state: {uid:props.shopUID, start: settings.hours[weekdayIndex].start, end: settings.hours[weekdayIndex].end, dayOfWeek: weekdayIndex}});
                            }
                            } color="primary" sx={{p:0, mt:2, mr:2}}>
                            <EditIcon sx={{ height: 20, width: 20 }} />
                        </Link>
                    </Box>
                </Stack>
                </Paper>)}</Stack>)
            )}
    </>)}
    </>);
}

export default SettingHourRange;
