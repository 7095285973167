import {useState, useEffect} from 'react';
import {Container, Grid, Paper, Fab, Typography} from '@mui/material';
import {getZones} from './constants/api_index';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate, useOutletContext} from 'react-router-dom';
import Message from './Message';
import Loader from './Loader';
import ShopHeader from './ShopHeader';
import IndexItem from './IndexItem';
import FabAddZone from './FabAddZone';
import slugify from 'slugify';

const Index = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(undefined);
    const navigate = useNavigate();
    const {shopUID} = useOutletContext();
    const [shopUIDPrev, setShopUIDPrev] = useState(shopUID);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            getZones(shopUID).then(data => setData(data))
        }
        if(loaded && shopUID !== shopUIDPrev) {
            setShopUIDPrev(shopUID);
            getZones(shopUID).then(data => setData(data));
        }
    }, [loaded, data, shopUID, shopUIDPrev]);

    return (<>{data !== undefined && (<Container>
        <Message />
        <Fab variant="contained" sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }} color="primary" onClick={() => navigate('add')} aria-label="Añadir"><AddIcon /></Fab>
        {data === undefined && (<Loader />)}
        {data !== undefined && Object.keys(data).length > 0 && Object.keys(data).map((data_key, data_key_index) => (<Grid key={"zi-dict-"+data_key_index} container spacing={2}>
            <ShopHeader title={data_key} />
            {data[data_key].map((row, index) => <IndexItem key={"i-paper-"+index} data={row} />)}
            <FabAddZone target={"add/"+slugify(data_key, {lower: true})}/>
            </Grid>))}
            {(data === undefined || Object.keys(data).length === 0) && (<Paper sx={{p:8}}>
                <Typography align="center"variant="h6">No hay Sectores para mostrar</Typography>
                </Paper>)}
                </Container>)}</>);
}

export default Index;
