import {useState, useEffect} from 'react';
import {retrieveProfile} from './constants/functions/profile';
import {Container, Stack} from '@mui/material';
import Message from './Message';
import Name from './Name';
import Email from './Email';
import Phone from './Phone';

const ProfileIndex = () => {
    const [loaded, setLoaded] = useState(false);
    const [profile, setProfile] = useState(undefined);
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveProfile().then((data) => {console.log(data); setProfile(data);});
        }
    }, [loaded]);

    return (<Container maxWidth="xs">

        <Message />
        {loaded && profile !== undefined && (<Stack spacing={2}>
            <Name first_name={profile.name.first_name} last_name={profile.name.last_name} />
            <Email email={profile.email} new_email={profile.new_email} />
            <Phone phone={profile.phone.value} new_phone={profile.new_phone} />
            </Stack>)}
            </Container>);
}

export default ProfileIndex;
