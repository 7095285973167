import {Box, Typography} from '@mui/material';

const BookingDataHeader = (props) => {
    const pagination = props.pagination;
    return (<Box display="flex" justifyContent="flex-end" sx={{pb:2}}>
        <Typography variant="body2">{pagination.count} {(pagination.count > 1 || pagination.count === 0) ? (<>reservas</>) : (<>reserva</>)} en {pagination.pages} {pagination.pages !== 1 ? (<>páginas</>) : (<>página</>)}</Typography>
        </Box>);
}

export default BookingDataHeader;
