import {useState, useEffect} from 'react';
import {InputLabel, MenuItem, FormControl} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {monthsSpanish} from '../constants/text';
import {useNavigate, useParams} from 'react-router-dom';

const MonthSelect = (props) => {
    const [loaded, setLoaded] = useState(false);
    const {monthURL} = useParams();
    const [month, setMonth] = useState(monthURL !== undefined ? parseInt(monthURL) : new Date().getMonth() + 1);
    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent) => {
        const newValue = parseInt(event.target.value);
        setMonth(newValue);
        props.setMonth(newValue);
        navigate('/reporte/'+props.year+'/'+newValue);
        props.setReloaded(false);
    };

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    return (<>{props.year !== undefined && month !== undefined && (<FormControl fullWidth>
        <InputLabel id="month-select-label">Mes</InputLabel>
        <Select
        labelId="month-select-label"
        id="month-select"
        value={month}
        label="Mes"
        onChange={handleChange}>
        {monthsSpanish.map((month, index) => <MenuItem key={"month-select-"+index} value={index + 1}>{month}</MenuItem>)}
        </Select></FormControl>)}</>);
}

export default MonthSelect;
