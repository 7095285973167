import CircularProgress from '@mui/material/CircularProgress';
import {Box} from '@mui/material';

const Loader = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ py: 4, width: "100%" }}>
      <CircularProgress />
    </Box>
  );
}

export default Loader;
