import {useEffect, useState} from 'react';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import {get} from '../Shop/constants/api';
import {useParams, useOutletContext} from 'react-router-dom';
import {info} from '../Shop/constants/api';

const ShopSelect = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shops, setShops] = useState(undefined);
    const {slug} = useParams();
    const shopUID = props.shopUID;
    const context = useOutletContext();
    const setShopUID = props.setShopUID;

    const handleChange = (event) => {
        setShopUID(event.target.value);
    };

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            setLoading(true);
            get().then((data) => {
                setShops(data);
            });
            if(typeof context.shopUID === 'number') {
                setShopUID(context.shopUID);
                setLoading(false);
            } else if(typeof slug === 'string') {
                info(slug).then((data) => {
                    setShopUID(data.uid);
                    setLoading(false);
                })
            } else {
                setLoading(false);
            }
        }
    }, [loaded, props, shopUID, setShopUID, slug, context]);

    return (<>
        {!loading && shops !== undefined && shops.length > 0 && (<FormControl fullWidth><InputLabel id="select-shop-id">Restaurante</InputLabel><Select id="select-shop-uid" inputProps={{name:"shop_uid"}} value={shopUID} onChange={handleChange} label={"Restaurante"}>
                <MenuItem value="">
                    <em>Sin asignar</em>
                </MenuItem>
                {shops.map((shop, index) => <MenuItem key={"ss-"+index} value={shop.uid}>{shop.name}</MenuItem>)}
                </Select></FormControl>)}
    </>);

}

export default ShopSelect;
