import {Grid, Paper, Stack, List, ListItem, ListItemText, Divider, Box, IconButton} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const IndexItem = (props) => {
    const data = props.data;
    const navigate = useNavigate();
    return (
        <Grid item xs={12} lg={3}><Paper>
                <Stack>
                    <Stack direction="row">
                        <List>
                            <ListItem>
                                <ListItemText primary="Sector" secondary={data.name} />
                            </ListItem>
                        </List>
                        <Divider orientation="vertical" flexItem />
                        <List>
                            <ListItem>
                                <ListItemText primary="Restaurante" secondary={data.shop_name} />
                            </ListItem>
                        </List>
                    </Stack>
                    <Box>
                        <Divider />
                        <Stack direction="row" spacing={2} display="flex" justifyContent="space-between" sx={{p:2}}>
                            <IconButton onClick={() => navigate('remove', {state: {uid: data.uid}})} aria-label="Borrar" size="large">
                                <DeleteIcon />
                            </IconButton>

                            <IconButton onClick={() => navigate('edit', {state: {uid: data.uid}})} aria-label="Editar" size="large">
                                <EditIcon />
                            </IconButton>
                        </Stack>
                    </Box>
                </Stack>
        </Paper></Grid>
        );
}
export default IndexItem;
