// CRUD 
import {endpoint} from './endpoints';

export const put = (event) => {
    const data = new FormData(event.currentTarget);
    const options = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'name': data.get('name'), 'shop_uid': (data.get('shop_uid') === "") ? "" : parseInt(data.get('shop_uid'))})
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const getZoneBySlug = (shop_slug, zone_slug) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    };
    return fetch(endpoint+shop_slug+'/'+zone_slug, options).then((response) => response.json());
}

export const get = (shop_uid = null, asdict = false) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    };
    if(shop_uid !== null) {
        return fetch(endpoint+shop_uid, options).then((response) => response.json());
    }
    return fetch(endpoint, options).then((response) => response.json());
}

export const post = (uid) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const patch = (event, uid) => {
    const data = new FormData(event.currentTarget);
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid, name: data.get('name'), 'shop_uid': (data.get('shop_uid') === "") ? "" : parseInt(data.get('shop_uid'))}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const remove = (uid) => {
    const options = {
        method: "DELETE",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}
