// CRUD 
import {endpoint_index} from './endpoints';

export const getZones = (shop_uid = null, asdict = false) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    };
    if(shop_uid !== null) {
        return fetch(endpoint_index+shop_uid, options).then((response) => response.json());
    }
    return fetch(endpoint_index, options).then((response) => response.json());
}
