import {useState, useEffect} from 'react';
import * as React from 'react';
import {Popover, Paper, Stack, Typography, Button, Avatar, List, ListItemButton, ListItemIcon, ListItemText, IconButton} from '@mui/material';
import {get} from './Shop/constants/api';
import CloseIcon from '@mui/icons-material/Close';

const ShopPopover = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [shops, setShops] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [selected, setSelected] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleSelectClick = (_shop) => {
        props.setShopUID(parseInt(_shop.uid));
        setSelected(_shop);
        handleClose();
    }
    const handleClear = () => {
        setSelected(null);
        props.setShopUID(null);
    }
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            get().then((data) => setShops(data));
        }
    }, [loaded]);

    return (<>{shops.length > 0 && (<>
        {selected === null ? (<>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                Restaurantes
            </Button>
        </>) : (<Paper sx={{pr:2}}>
        <Stack spacing={1} direction="row" display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={() => handleClear()}><CloseIcon /></IconButton>
            <Typography variant="body2">{selected.name}</Typography>
            <Avatar src={selected.avatar} sx={{ width:32, height: 32 }} /></Stack>
        </Paper>)}
    <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left', }}>
    <List spacing={2}>
        {Array.isArray(shops) && shops.length > 0 && shops.map((shop, index) => (
        <ListItemButton key={"sp-item-"+index} onClick={() => handleSelectClick(shop)}>
            <ListItemIcon>
                <Avatar src={shop.avatar} />
            </ListItemIcon>
            <ListItemText>
                <Typography variant="body2">{shop.name}</Typography>
            </ListItemText>
        </ListItemButton>
        ))}
    </List>
</Popover>
</>)}</>);
}

export default ShopPopover;
