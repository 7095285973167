import { useState, useEffect, useRef  } from 'react';
import {Canvas, Rect, Text, Group} from 'fabric';
import {Container} from '@mui/material';
import {get, patch} from './constants/api'; 
import {useOutletContext, useNavigate} from 'react-router-dom';

const Diagram = (props) => {
    const canvas = useRef(null);
    const fabric = useRef(null);
    const context = useOutletContext();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [objectsCreated, setObjectsCreated] = useState(false);
    const [shopUIDPrev, setShopUIDPrev] = useState(context.shopUID);

    useEffect(() => {
        const initCanvas = (canvas) => (
            new Canvas(canvas.current, {
                id: 'diagram-canvas-'+props.index,
                height: 350,
                width: '1110',
                backgroundColor: 'white',
                selection: false,
                renderOnAddRemove: true,
            })
        );

        const preventLeavingInsideCanvas = (e) => {
            const activeObject = e.target;
            const activeObjectDetails = {
                top: activeObject.top,
                left: activeObject.left,
                right: activeObject.left + activeObject.width * activeObject.scaleX,
                bottom: activeObject.top + activeObject.height * activeObject.scaleY,
                width: activeObject.width * activeObject.scaleX,
                height: activeObject.height * activeObject.scaleY,
            };
            // right
            if (activeObjectDetails.right >= canvas.current.width) {
                activeObject.set({
                    left: canvas.current.width - activeObjectDetails.width,
                });
            }
            // left
            if (activeObjectDetails.left === undefined || activeObjectDetails.left === null || activeObjectDetails.left < 0) {
                activeObject.set({
                    left: 0,
                });
            }
            // bottom
            if (activeObjectDetails.bottom > canvas.current.height) {
                activeObject.set({
                    top: canvas.current.height - activeObjectDetails.height,
                });
            }
            // Top
            if (activeObjectDetails.top === undefined || activeObjectDetails.top === null || activeObjectDetails.top <= 0) {
                activeObject.set({
                    top: 0,
                });
            }
        };

        fabric.current = initCanvas(canvas);
        fabric.current.on('object:modified', (e) => {
            patch(e.target.uid, e.target.left, e.target.top).then(data => console.log(data));
        })
        fabric.current.on('object:moving', preventLeavingInsideCanvas);
        return () => {
            fabric.current.dispose();
            fabric.current = null;
        };
    }, [props.index]);

    useEffect(() => {
        const addRectangle = (name, uid, bookings, _left=50, _top=50) => {
            const rect = new Rect({
                width: 50,
                height: 50,
                originX: 'center',
                originY: 'center',
                fill: bookings.length === 0 ? "brown" : "green",
            });

            const text = new Text(name, {
                originX: 'center',
                originY: 'center',
                textAlign: 'center',
                fontSize: 25,
                fill: 'white',
            });

            const group = new Group([rect, text], {
                top: _top,
                left: _left,
                lockRotation: true,
                lockScalingY: true,
                lockScalingX: true,
            });

            group.set('uid', uid);
            fabric.current.add(group);
            //fabric.current.centerObject(rect);
            return group;
        };

        const createTables = () => {
            get(props.zoneUID).then(data => {
                data?.forEach((row) => {
                    addRectangle(row.name, row.uid, row.bookings, row.left, row.top);
                });
            });
        }

        if(!loaded) {
            setLoaded(true);
        }
        if(loaded && !objectsCreated) {
            setObjectsCreated(true);
            createTables();
        }
    }, [loaded, navigate, props, objectsCreated])

    useEffect(() => {
        if(context.shopUID !== shopUIDPrev) {
            console.log('reload all!');
            setShopUIDPrev(context.shopUID);
            navigate('/diagrama');
        }
    }, [shopUIDPrev, context.shopUID, navigate]);

    useEffect(() => {
        const intervalID = setInterval(() => updateTables(), 5000);

        const updateTables = () => {
            get(props.zoneUID).then(data => {
                if(fabric.current !== null && fabric.current._objects !== undefined && fabric.current._objects !== null) {
                    fabric.current._objects?.forEach((obj, index) => {
                        const row = data.filter(row => row.uid === obj.uid)[0];
                        if(row !== undefined && row !== null) {
                            obj._objects[0].set('fill', (row.bookings?.length) === 0 ? "brown" : "green");
                            //obj._objects[0].dirty = true;
                        }
                        if(fabric.current._objects?.length > 0 && fabric.current._objects.length - 1 === index) {
                            fabric.current.renderAll();
                        }
                    });
                }
            });
        }
        return () => clearInterval(intervalID);
    }, [props.zoneUID]);

    return (<Container maxWidth="lg">
        <canvas id={"html-diagram-canvas-"+props.index} ref={canvas} />
        </Container>);
}

export default Diagram;
