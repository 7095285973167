import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Container, Paper, Stack, TextField, Button} from '@mui/material';
import {patch, post} from './constants/api';
import Message from './Message';
import ShopSelect from './ShopSelect';
import ZoneSelect from './ZoneSelect';

const Edit = () => {
    const [loaded, setLoaded] = useState(false);
    const [finishLoad, setFinishLoad] = useState(false);
    const [name, setName] = useState('');
    const [shopUID, setShopUID] = useState('');
    const [zoneUID, setZoneUID] = useState('');
    const [slots, setSlots] = useState(4);
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            post(uid).then((data) => {
                console.log(data);
                setName(data.name);
                setShopUID(data.shop_uid);
                setZoneUID(data.zone_uid);
                setSlots(data.slots);
                setFinishLoad(true);
            });
        }
    }, [loaded, uid]);

    const handleSubmit = (event) => {
        event.preventDefault();
        patch(event, uid).then((data) => {
            if(data.status === 'success' || data.status === 'info') {
                navigate("/mesas/", {state:{message:data}});
            } else {
                navigate(".", {state:{message:data, uid: uid}});
            }
        });
    }

    return (<Container maxWidth="xs">
        {loaded && finishLoad && (<>
        <Message />
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack spacing={2}>
                <TextField
                name="name"
                value={name}
                onChange={(event, newValue) => setName(newValue)}
                required
                fullWidth
                id="name"
                label="Nombre Mesa"
                inputProps={{ maxLength: 3 }}
                autoFocus />

                <TextField type="number" name="slots" label="Capacidad en número de personas" defaultValue={slots} onChange={(event, newValue) => setSlots(newValue)} />

                <ShopSelect shopUID={shopUID} setShopUID={setShopUID} />
                <ZoneSelect shopUID={shopUID} zoneUID={zoneUID} setZoneUID={setZoneUID} />

                <Stack direction="row" spacing={2}>
                    <Button onClick={() => navigate('/mesas/', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                        Cancelar
                    </Button>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                        Guardar
                    </Button>
                </Stack>
            </Stack>
        </Paper>
        </>)}
        </Container>);
}

export default Edit;
