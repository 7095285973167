import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Container, Paper, Stack, TextField, Button, Box, Avatar} from '@mui/material';
import {post, patch} from './constants/api';
import { MuiFileInput } from 'mui-file-input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';


const Edit = () => {
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);
    const [blob, setBlob] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            post(uid).then((data) => {
                setName(data.name);
                setCurrentFile(data.avatar);
            });
        }
    }, [loaded, name, uid]);

    const handleFileChange = (newValue) => {
        console.log(newValue);
        setFile(newValue);
        if(newValue !== null) {
            setBlob(URL.createObjectURL(newValue))
        } else {
            URL.revokeObjectURL(blob);
            setBlob(null);
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        patch(event, uid, file).then((data) => {
            navigate("/restaurantes/", {state:{message:data}});
        });
    }

    return (<Container maxWidth="xs">
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack spacing={2}>
                {blob === null && currentFile !== null && (<Box display="flex" justifyContent="center"><Avatar sx={{m:2, width: 128, height: 128}} src={currentFile} /></Box>)}
                {blob !== null && (<Box display="flex" justifyContent="center"><Avatar sx={{m:2, width: 128, height: 128}} src={blob} /></Box>)}

                <TextField
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
                id="name"
                label="Nombre Restaurante"
                autoFocus />
                <MuiFileInput label="Nuevo Logo 128x128 max." value={file} onChange={handleFileChange} inputProps={{ accept: "image/png, image/gif, image/jpeg" }} 
                InputProps={{startAdornment: <AttachFileIcon /> }} clearIconButtonProps={{ title: "Quitar", children: <CloseIcon fontSize="small" /> }} />
            <Stack direction="row" spacing={2}>
                <Button onClick={() => navigate('/restaurantes/', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                    Cancelar
                </Button>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                    Guardar
                </Button>
            </Stack>
        </Stack>
    </Paper>
    </Container>);
}

export default Edit;
