import {useEffect, useState} from 'react';
import {Container} from '@mui/material';
import {retrieveSettings} from './constants/api';
import SettingDayRange from './DayRange';
import SettingHourRange from './HourRange';
import {useLocation} from 'react-router-dom';
import BackButton from './BackButton';

const SettingIndex = () => {
    const [loaded, setLoaded] = useState(false);
    const [settings, setSettings] = useState(undefined);
    const _location = useLocation();
    const shopUID = _location.state.uid;

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveSettings(shopUID).then((data) => {
                setSettings(data);
            });
        }
    }, [loaded, shopUID]);
    return (<Container maxWidth="xs" sx={{mt:2}}>
        <SettingDayRange settings={settings} shopUID={shopUID} />
        <SettingHourRange settings={settings} shopUID={shopUID} />
        <BackButton link="/restaurantes/" />
        </Container>);
}

export default SettingIndex;
