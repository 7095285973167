import {useState, useEffect} from 'react';
import {Container, Grid, Paper, Typography} from '@mui/material';
import {get} from './constants/api_index';
import {useOutletContext} from 'react-router-dom';
import Message from './Message';
import Loader from './Loader';
import ShopHeader from './ShopHeader';
import IndexItem from './IndexItem';
import FabAdd from './FabAdd';
import FabAddAgenda from './FabAddAgenda';
import slugify from 'slugify';

const Index = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(undefined);
    const {shopUID} = useOutletContext();
    const [shopUIDPrev, setShopUIDPrev] = useState(shopUID);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            get(shopUID).then(data => setData(data))
        }
        if(loaded && shopUID !== shopUIDPrev) {
            setShopUIDPrev(shopUID);
            get(shopUID).then(data => setData(data));
        }
    }, [loaded, data, shopUID, shopUIDPrev]);

    return (<Container>
        <Message />
        <FabAdd target={"add"} />
        {data !== undefined && data !== null && Object.keys(data).map((data_key, data_key_index) => (<Grid key={"ai-dict-"+data_key_index} container spacing={2}>
            <ShopHeader title={data_key} first={data_key_index === 0}/>
            {data[data_key].map((row, index) => <IndexItem key={"ai-grid-"+data_key_index+'-'+index} data={row} />)}
            <FabAddAgenda target={"add/"+slugify(data_key.split(' / ')[0], {lower: true}) + '/' + slugify(data_key.split(' / ')[1], {lower: true})}/>
            </Grid>))}
            {data === undefined && (<Loader />)}
            {data !== undefined && Object.keys(data).length === 0 && (<Paper sx={{p:8}}>

                <Typography align="center"variant="h6">No hay Mesas para mostrar</Typography>
                </Paper>)}
                </Container>);
}

export default Index;
