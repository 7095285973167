import {endpoint} from '../endpoints';

export const saveName = (event) => {
    const data = new FormData(event.currentTarget);
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'first_name': data.get('first_name'), 'last_name': data.get('last_name')})
    }
    return fetch(endpoint, options)
        .then((response) => response.json());

}
