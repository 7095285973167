import {useState, useEffect} from 'react';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import {Container, Paper, Stack, TextField, Button} from '@mui/material';
import {put} from './constants/api';
import Message from './Message';
import ShopSelect from './ShopSelect';

const Add = () => {
    const [loaded, setLoaded] = useState(false);
    const _location = useLocation();
    const navigate = useNavigate();
    const uid = _location.state?.uid || '';
    const context = useOutletContext();
    const [shopUID, setShopUID] = useState('');

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    const handleSubmit = (event) => {
        event.preventDefault();
        put(event).then((data) => {
            if(data.status === 'success') {
                navigate("/sectores/", {state:{message:data}});
            } else {
                navigate(".", {state:{message:data, uid: uid}});
            }
        });
    }

    return (<Container maxWidth="xs">
        <Message />
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack spacing={2}>
                <TextField
                name="name"
                required
                fullWidth
                id="name"
                label="Nombre Sector"
                autoFocus />
                <ShopSelect shopUID={shopUID} setShopUID={setShopUID} defaultValue={context.shopUID !== undefined ? context.shopUID : ''} />
                <Stack direction="row" spacing={2}>
                    <Button onClick={() => navigate('/sectores/', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                        Cancelar
                    </Button>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                        Guardar
                    </Button>
                </Stack>
            </Stack>
        </Paper>
        </Container>);
}

export default Add;
