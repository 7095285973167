import {endpoint_agenda, endpoint_zones_diagram} from './endpoints';

export const patch = (uid, _left, _top) => {
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid, x: (_left === null || _left === undefined) ? 0 : _left, y: (_top === null || _top === undefined) ? 0 : _top})
    }
    return fetch(endpoint_agenda, options)
        .then((response) => response.json())
}

export const get = (zoneUID) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoint_agenda+'zone/'+zoneUID, options)
        .then((response) => response.json())
}

export const getZones = (shopUID=null) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    if(shopUID === null || shopUID === undefined) {
        return fetch(endpoint_zones_diagram, options)
            .then((response) => response.json())
    }
    return fetch(endpoint_zones_diagram+shopUID, options)
        .then((response) => response.json())
}
