import {useState} from 'react';
import {Container, Paper, Box, Slider, Typography, Alert} from '@mui/material';
import {weekDaysSpanish} from './constants/text';
import {sendSettingsDaysRange} from './constants/api';
import BackButton from './BackButton';
import {useLocation} from 'react-router-dom';
//import Message from './Message';
const marks = [
    {
        value: 0,
        label: weekDaysSpanish[0],
    },
    {
        value: 6,
        label: weekDaysSpanish[6],
    },
]

const calculateValue = (value) => {
    return value;
}

const labelFormat = (value) => {
    return weekDaysSpanish[value];
}

const SettingDayRangeEdit = (props) => {
    const _location = useLocation();
    const shopUID = _location.state.uid;
    const title = props.title || 'Selección de Días Disponibles';
    const defaultStart = props.start || _location.state.week_range[0];
    const defaultEnd = props.end || _location.state.week_range[1];
    const [values, setValues] = useState([defaultStart,defaultEnd]);
    const handleChange = (event, newValues: number[], activeThumb) => {
        setValues(newValues);
    }
/*    const handleChangeWithDistance = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        const minDistance = 1;
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValues([Math.min(newValue[0], values[1] - minDistance), values[1]]);
        } else {
            setValues([values[0], Math.max(newValue[1], values[0] + minDistance)]);
        }
    };*/
    const handleChangeCommitted = (event, newValues: number[], activeThumb) => {
        sendSettingsDaysRange(shopUID, values).then((data) => console.log(data));
        // navigate('.', {state:{message:data, week_range: values}}));
    }

    return (<Container maxWidth="xs" sx={{mt:2}}>
        <Paper sx={{p:2}}>
            <Box sx={{ width: '100%' }} display="flex" justifyContent="center" alignItems="center">
                <Typography align="center" variant="h5"sx={{mb:4}}>{title}</Typography>
            </Box>
            <Box sx={{ p:4, width: '100%' }} display="flex" justifyContent="center" alignItems="center">
                <Slider
                getAriaLabel={() => 'Horario de funcionamiento'}
                min={0}
                max={6}
                step={1}
                scale={calculateValue}
                value={values}
                onChange={handleChange}
                onChangeCommitted={handleChangeCommitted}
                valueLabelFormat={labelFormat}
                marks={marks}
                valueLabelDisplay="on"
                disableSwap />
            </Box>
            <Alert severity="info">{(values[0] === 0 && values[1] === 6) ? 'Las reservas están disponibles todos los días de la semana' : `Las reservas están disponible entre los días ${labelFormat(values[0])} y ${labelFormat(values[1])}`}</Alert>
        </Paper>
        <BackButton link={-1} />
        </Container>);
}

export default SettingDayRangeEdit;
