import {Grid, Paper, Box, Fab} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const FabAddAgenda = (props) => {
    const target = props.target;
    const navigate = useNavigate();
    return (<Grid xs={12} lg={3} item>
        <Paper sx={{height: "100%", minHeight: 100, opacity: 0.5}}>
            <Box display="flex" alignItems="center" justifyContent="center"  sx={{p:2, minHeight: 100, height: "100%"}}>
                <Fab variant="contained" color="secondary" onClick={() => navigate(target)} aria-label="Añadir"><AddIcon /></Fab>
            </Box>
        </Paper>
        </Grid>);
}
export default FabAddAgenda;
