import {useEffect, useState} from 'react';
import {getZones} from './constants/api';
import {useOutletContext} from 'react-router-dom';
import Diagram from './Diagram';
import {Container, Grid} from '@mui/material';
import ZoneHeader from './ZoneHeader';

const Index = () => {
    const [loaded, setLoaded] = useState(false);
    const [zones, setZones] = useState(undefined);
    const context = useOutletContext();
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            getZones(context.shopUID).then(data => setZones(data));
        } else {
            getZones(context.shopUID).then(data => setZones(data));
        }

    }, [loaded, context.shopUID]);

    return (<Container maxWidth="lg">{zones !== undefined && Object.keys(zones).length > 0 && Object.keys(zones).map((zone_key, index) => (<Grid key={"diagram-grid-"+zones[zone_key].uid+'-'+index} container>
        <Grid item xs={12}>
            <ZoneHeader title={zone_key} />
        </Grid>
        <Grid item xs={12} sx={{p:0}}>
            <Diagram index={zones[zone_key].uid+'-'+index} zoneUID={zones[zone_key].uid} />
        </Grid>
        </Grid>))}</Container>);
}

export default Index;
