import { useEffect, useState, forwardRef } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { useNavigate, NavLink} from 'react-router-dom';

// icons
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import StoreIcon from '@mui/icons-material/Store';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import BorderVerticalIcon from '@mui/icons-material/BorderVertical';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StorefrontIcon from '@mui/icons-material/Storefront';
import './IconsLayoutMenu.css';

const CustomNavLink = forwardRef((props, ref) => (<NavLink
ref={ref}
to={props.to}
className={({ isActive }) => `${props.className} ${isActive ? props.activeClassName : props.inactiveClassName}`}>
{props.children}
</NavLink>));

const DrawerMenu = (props) => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(null);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    return (
        <>
        <List sx={{p:0}}>
            <ListItem component={CustomNavLink} activeClassName='active-link' inactiveClassName="inactive-link" to="/diagrama" key="menu-pos" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/diagrama')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/diagrama')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <StorefrontIcon />
                    </ListItemIcon>
                    <ListItemText primary="Diagrama" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>

            <ListItem component={CustomNavLink} activeClassName='active-link' inactiveClassName="inactive-link" to="/bookings" key="menu-bookings" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/bookings')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/bookings')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reservas" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem component={CustomNavLink} to="/restaurantes" activeClassName="active-link" inactiveClassName="inactive-link" key="menu-shops" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/restaurantes/')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/restaurantes/')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Restaurantes" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <ListItem component={CustomNavLink} to="/sectores" activeClassName="active-link" inactiveClassName="inactive-link" key="menu-sectors" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/sectores/')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/sectores/')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <BorderVerticalIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sectores" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <ListItem component={CustomNavLink} to="/mesas" activeClassName="active-link" inactiveClassName="inactive-link" key="menu-tables" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/mesas/')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/mesas/')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <TableRestaurantIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Mesas" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem component={CustomNavLink} activeClassName='active-link' inactiveClassName="inactive-link" to="/reporte" key="menu-reporte" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/reporte')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/reporte')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reporte" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem component={CustomNavLink} to="/profile" activeClassName="active-link" inactiveClassName="inactive-link" key="menu-profile" disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                onClick={() => navigate('/profile/')}
                sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
                }}
            >
                    <ListItemIcon
                    onClick={() => navigate('/profile/')}
                    sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mis Datos" sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        </List>
        </>);
}
export default DrawerMenu;
