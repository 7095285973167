// CRUD 
import {endpoint} from './endpoints';

export const post = (shop_uid, year=null, month=null) => {
    const _shop_uid = parseInt(shop_uid);
    const _year = parseInt(year) || null;
    const _month = parseInt(month) || null;

    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({shop_uid: _shop_uid, year: _year, month: _month}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}
