import {endpoints} from '../endpoints';

export const jwtValidate = () => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoints.jwt_validate, options)
        .then((response) => response.json());
};

export const jwtRefresh = () => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoints.jwt_refresh, options)
        .then((response) => response.json());
};
