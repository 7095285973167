import {useState, useEffect} from 'react';
import {Container, Paper, Grid, Button} from '@mui/material';
import {savePhone} from './constants/functions/phone';
import {retrieveProfile} from './constants/functions/profile';
import {useNavigate} from 'react-router-dom';
import Message from './Message';
import {MuiTelInput} from 'mui-tel-input';

const ProfilePhoneEdit = (props) => {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState('CL');

    const handleSubmit = (event) => {
        event.preventDefault();
        savePhone(phone).then((data) => {
            if(data.status === "success" || data.status === "info") {
                navigate('/profile/', {state: {message: data}});
            } else {
                navigate('.', {state: {message: data}});
            }
        });
    }
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveProfile().then((data) => {
                if(data.phone.value !== null) {
                    setPhone(data.phone.value);
                    setCountryCode(data.phone.country_code);
                } else {
                    setCountryCode('CL');
                    setPhone('');
                }
            });
        }
    }, [loaded]);
    return (<>{loaded && (
        <Container maxWidth="xs">
            <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
                <Message />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MuiTelInput name="phone" fullWidth value={phone} defaultCountry={countryCode} forceCallingCode onChange={(value) => setPhone(value)}/>
                    </Grid>
                </Grid>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, }}>
                Guardar 
            </Button>
        </Paper>
    </Container>
    )}</>);
}

export default ProfilePhoneEdit;
