import {useEffect, useState} from 'react';
import {ResponsiveChartContainer, ChartsXAxis, ChartsYAxis, ChartsLegend, AreaPlot, LinePlot, LineHighlightPlot, ChartsAxisHighlight, ChartsTooltip } from '@mui/x-charts';
import {Container, Stack, Paper, Typography} from '@mui/material';
import {post} from './constants/api';
import dayjs from 'dayjs';
import {useParams, useOutletContext} from 'react-router-dom';
import YearSelect from './Select/Year';
import MonthSelect from './Select/Month';

const Report = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    const {shopUID} = useOutletContext();
    const [shopUIDPrev, setShopUIDPrev] = useState(shopUID);
    const {yearURL, monthURL} = useParams();
    const [reloaded, setReloaded] = useState(true);
    const [year, setYear] = useState(yearURL ? parseInt(yearURL) : new Date().getFullYear());
    const [month, setMonth] = useState(monthURL ? parseInt(monthURL) : new Date().getMonth() + 1);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            post(shopUID, yearURL, monthURL).then(_data => setData(_data));
        }
        if((loaded && shopUID !== shopUIDPrev) || !reloaded) {
            setReloaded(true);
            setShopUIDPrev(shopUID);
            post(shopUID, yearURL, monthURL).then(data => setData(data))
        }
    }, [loaded, shopUID, shopUIDPrev, yearURL, monthURL, reloaded]);

    return (<>{data !== null && (<Container maxWidth="sm">
        <Stack spacing={2}>
            {data !== null && Array.isArray(data) && data.length > 0 && (
            <Stack direction="row" spacing={2}>
                <YearSelect reloaded={reloaded} setReloaded={setReloaded} setYear={setYear} month={month} />
                <MonthSelect reloaded={reloaded} setReloaded={setReloaded} setMonth={setMonth} year={year} />
                </Stack>)}

                {data !== null && Array.isArray(data) && data.map((row, index) => <Paper key={"r-"+index}>
                    <ResponsiveChartContainer height={250}
                    xAxis={[{ scaleType: "time", valueFormatter: (date) => dayjs(date).format("D MMM YYYY"), label: 'Tiempo', data: row.x.map((v) => dayjs(v)) }]}
                    yAxis={[{tickMinStep: 1, tickMaxStep: 10, label: 'Reservas'}]}
                    series={[{area: true, label: row.name, type: 'line', data: row.y }]}>
                    <LineHighlightPlot />
                    <ChartsAxisHighlight x="line" />
                    <ChartsTooltip trigger="axis" />
                    <LinePlot />
                    <AreaPlot />
                    <ChartsXAxis  />
                    <ChartsYAxis />
                    <ChartsLegend />
                </ResponsiveChartContainer>
                </Paper>)}
            </Stack>
            {(data === null || (Array.isArray(data) && data.length === 0)) && (<Paper sx={{p:8}}>
                <Typography align="center"variant="h6">No hay Reportes para mostrar</Typography>
                </Paper>)}

                </Container>)}</>);
}
export default Report;
