import {useState, useEffect} from 'react';
import {Container, Paper, Grid, Button, TextField} from '@mui/material';
import {saveName} from './constants/functions/name';
import {retrieveProfile} from './constants/functions/profile';
import {useNavigate} from 'react-router-dom';
import Message from './Message';

const ProfileNameEdit = (props) => {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        saveName(event).then((data) => {
            if(['success', 'info'].includes(data.status)) {
                navigate('/profile/', {state: {message: data}});
            } else {
                navigate('.', {state: {message: data}});
            }
        });
    }
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveProfile().then((data) => {
                setFirstName(data.name.first_name);
                setLastName(data.name.last_name);
            });
        }
    }, [loaded]);
    return (<>{loaded && (
        <Container maxWidth="xs">
            <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Message />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        autoComplete="given-name"
                        name="first_name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        fullWidth
                        id="nombre"
                        label="Nombre"
                        autoFocus />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        autoComplete="family-name"
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        fullWidth
                        id="apellido"
                        label="Apellido"
                        name="last_name" />
                    </Grid>
                </Grid>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, }}>
                Guardar 
            </Button>
        </Paper>
    </Container>
    )}</>);
}

export default ProfileNameEdit;
