import Layout from './components/Layout';
import IconsLayout from './components/IconsLayout';
import NotFound from './components/NotFound';
import Signup from './components/Auth/Signup/Signup';
import Signin from './components/Auth/Signin/Signin';
import Activate from './components/Auth/Activate/Activate';
import ActivateNewEmail from './components/Profile/ActivateNewEmail/ActivateNewEmail';
import ActivateNewPhone from './components/Profile/ActivateNewPhone/ActivateNewPhone';
import PasswordForgot from './components/Auth/PasswordForgot/PasswordForgot';
import PasswordForgotSent from './components/Auth/PasswordForgot/Sent';
import PasswordForgotChange from './components/Auth/PasswordForgot/Change';
import PasswordForgotModified from './components/Auth/PasswordForgot/Modified';
import SettingIndex from './components/Shop/Setting/Index';
import SettingDayRangeEdit from './components/Shop/Setting/DayRangeEdit';
import SettingHourRangeEdit from './components/Shop/Setting/HourRangeEdit';
import ProfileIndex from './components/Profile/Index';
import ProfileNameEdit from './components/Profile/NameEdit';
import ProfileEmailEdit from './components/Profile/EmailEdit';
import ProfilePhoneEdit from './components/Profile/PhoneEdit';
import BookingIndex from './components/Booking/Index';
import ReportIndex from './components/Report/Index';

import {default as ShopIndex} from './components/Shop/Index';
import {default as ShopEdit} from './components/Shop/Edit';
import {default as ShopRemove} from './components/Shop/Remove';
import {default as ShopAdd} from './components/Shop/Add';

import {default as ZoneIndex} from './components/Zone/Index';
import {default as ZoneEdit} from './components/Zone/Edit';
import {default as ZoneRemove} from './components/Zone/Remove';
import {default as ZoneAdd} from './components/Zone/Add';

import {default as AgendaIndex} from './components/Agenda/Index';
import {default as AgendaEdit} from './components/Agenda/Edit';
import {default as AgendaRemove} from './components/Agenda/Remove';
import {default as AgendaAdd} from './components/Agenda/Add';

import Diagram from './components/Diagram/Index';

import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";

const router = createBrowserRouter([
    {   
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <Navigate to="/auth/sign-in/" replace />
                },
        ]
    },
    {   
        path: "/auth",
        element: <Layout />,
        children: [

            {
                path: "activate/email/:uid",
                element: <Activate />
                },
            {
                path: "password-forgot/change/:uid",
                element: <PasswordForgotChange />
                },
            {
                path: "password-forgot/modified",
                element: <PasswordForgotModified />
                },
            {
                path: "password-forgot/",
                element: <PasswordForgot />
                },
            {
                path: "password-forgot/sent",
                element: <PasswordForgotSent />
                },
            {
                path: "sign-in",
                element: <Signin />
                },
            {
                path: "sign-up",
                element: <Signup />
                },
        ],
    },
    {   
        path: "/mesas/",
        element: <IconsLayout />,
        children: [
            {
                path: "",
                element: <AgendaIndex />
                },
            {
                path: "add/:shop_slug/:zone_slug",
                element: <AgendaAdd />
                },
            {
                path: "add",
                element: <AgendaAdd />
                },
            {
                path: "edit",
                element: <AgendaEdit />
                },
            {
                path: "remove",
                element: <AgendaRemove />
                },
        ],
    },
    {   
        path: "/diagrama/",
        element: <IconsLayout />,
        children: [
            {
                path: "",
                element: <Diagram />
                },
        ],
    },
    {   
        path: "/reporte/",
        element: <IconsLayout />,
        children: [
            {
                path: ":yearURL/:monthURL",
                element: <ReportIndex />
                },
            {
                path: "",
                element: <ReportIndex />
                },
        ],
    },
    {   
        path: "/sectores/",
        element: <IconsLayout />,
        children: [
            {
                path: "",
                element: <ZoneIndex />
                },
            {
                path: "add/:slug",
                element: <ZoneAdd />
                },
            {
                path: "add",
                element: <ZoneAdd />
                },
            {
                path: "edit",
                element: <ZoneEdit />
                },
            {
                path: "remove",
                element: <ZoneRemove />
                },
        ],
    },
    {   
        path: "/restaurantes/",
        element: <IconsLayout />,
        children: [
            {
                path: "",
                element: <ShopIndex />
                },
            {
                path: "add",
                element: <ShopAdd />
                },
            {
                path: "edit",
                element: <ShopEdit />
                },
            {
                path: "remove",
                element: <ShopRemove />
                },
        ],
    },
    {   
        path: "/restaurantes/settings/",
        element: <IconsLayout />,
        children: [
            {
                path: "",
                element: <SettingIndex />
                },
            {
                path: "edit/days",
                element: <SettingDayRangeEdit />
                },
            {
                path: "edit/hours",
                element: <SettingHourRangeEdit />
                },
        ],
    },
    {
        path: "/bookings/",
        element: <IconsLayout />,
        children: [
            {
                path: "",
                element: <BookingIndex />
                },
        ]
    },
    {   
        path: "/profile/",
        element: <IconsLayout />,
        children: [
            {
                path: "",
                element: <ProfileIndex />
                },
            {
                path: "edit/name",
                element: <ProfileNameEdit />
                },
            {
                path: "edit/email",
                element: <ProfileEmailEdit />
                },
            {
                path: "edit/phone",
                element: <ProfilePhoneEdit />
                },
            {
                path: "activate/new-email/:uid",
                element: <ActivateNewEmail />
                },
            {
                path: "activate/new-phone/:uid",
                element: <ActivateNewPhone />
                },
        ]
    },
    {
        path: "*",
        element: <NotFound />
        }
]);

function App() {

    return (
        <RouterProvider router={router} />
        );
}

export default App;
