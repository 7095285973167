import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Container, Paper, Box, Stack, TextField, Button, Avatar} from '@mui/material';
import {put} from './constants/api';
import Message from './Message';
import { MuiFileInput } from 'mui-file-input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';

const Add = () => {
    const [loaded, setLoaded] = useState(false);
    const [file, setFile] = useState(null);
    const [blob, setBlob] = useState(null);
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    const handleFileChange = (newValue) => {
        console.log(newValue);
        setFile(newValue);
        if(newValue !== null) {
        setBlob(URL.createObjectURL(newValue))
        } else {
            URL.revokeObjectURL(blob);
            setBlob(null);
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(event.currentTarget.files);
        put(event, file).then((data) => {
            if(data.status === 'success') {
                navigate("/restaurantes/", {state:{message:data}});
            } else {
                navigate(".", {state:{message:data, uid: uid}});
            }
        });
    }


    return (<Container maxWidth="xs">
        <Message />
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>


            {blob !== null && (<Box display="flex" justifyContent="center"><Avatar sx={{m:2, width: 128, height: 128}} src={blob} /></Box>)}

            <Stack spacing={2}>
                <TextField
                name="name"
                required
                fullWidth
                id="name"
                label="Nombre Restaurante"
                autoFocus />

                <MuiFileInput label="Logo 128x128 max." value={file} onChange={handleFileChange} inputProps={{ accept: "image/png, image/gif, image/jpeg" }} 

                InputProps={{startAdornment: <AttachFileIcon /> }} clearIconButtonProps={{ title: "Quitar", children: <CloseIcon fontSize="small" /> }} />

                <Stack direction="row" spacing={2}>
                    <Button onClick={() => navigate('/restaurantes/', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                        Cancelar
                    </Button>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                        Guardar
                    </Button>
                </Stack>
            </Stack>
        </Paper>
        </Container>);
}

export default Add;
