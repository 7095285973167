import {Paper, List, ListItem, ListItemText} from '@mui/material';
import {minuteFormat} from './constants/functions';
import {weekDaysSpanish, monthsSpanish} from './constants/text';

const BookingIndexItemDetails = (props) => {
    const data = props.data;
    return (
        <Paper sx={{ width:"70%", background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%)' }}>
            <List disablePadding={true} dense={true}>
                <ListItem>
                    <ListItemText primary="Hora" secondary={data.hour+":"+minuteFormat(data.minute)} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Fecha" secondary={weekDaysSpanish[data.weekday]+" "+data.day+' de '+monthsSpanish[data.month-1]+' del '+data.year} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Cantidad de Personas" secondary={data.slots} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Nombre Cliente" secondary={data.customer_first_name+' '+data.customer_last_name} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Teléfono Cliente" secondary={data.customer_phone} />
                </ListItem>
            </List>
        </Paper>
        );
}

export default BookingIndexItemDetails;
