import {Grid, Paper, Typography} from '@mui/material';
import {weekDaysSpanish, monthsSpanish} from './constants/text';

const BookingMonthDataHeader = (props) => {
    const {booking_key, booking_key_index} = props;
    const key_parts = booking_key.split('-');
    const year = parseInt(key_parts[0]);
    const month = parseInt(key_parts[1]);
    const day = parseInt(key_parts[2]);
    const weekday = parseInt(key_parts[3]);
    return (<Grid item xs={12}><Paper sx={{mt:(booking_key_index > 0)?2:0, p:2, background: 'linear-gradient(to right top, #00d4ff, #0a1172)', color: 'common.white'}}>
        <Typography align="right" variant="h5">{weekDaysSpanish[weekday]} {day} {monthsSpanish[month-1]} {year}</Typography>
        </Paper></Grid>);
}

export default BookingMonthDataHeader;
