import {endpoint} from '../endpoints';

export const saveEmail = (event) => {
    const data = new FormData(event.currentTarget);
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'email': data.get('email')})
    }
    return fetch(endpoint, options)
        .then((response) => response.json());

}
