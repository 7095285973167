import {useState, useEffect} from 'react';
import {Container, Fab, List, Grid, Paper, Typography} from '@mui/material';
import {get} from './constants/api';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from 'react-router-dom';
import Message from './Message';
import Loader from './Loader';
import IndexItem from './IndexItem';

const Index = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            get().then(data => setData(data))
        }
    }, [loaded, data]);

    return (<Container maxWidth="sm">

        <Fab variant="contained" sx={{ position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }} color="primary" onClick={() => navigate('add')} aria-label="Añadir"><AddIcon /></Fab>

    {Array.isArray(data) && data.length > 0 && (<List>
        <Message />
        <Grid container spacing={2}>
        {data.map((row, index) => <IndexItem key={"i-paper-"+index} data={row} />)}
        </Grid>

            </List>)}

            {data === undefined && (<Loader />)}

            {Array.isArray(data) && data.length === 0 && (<Paper sx={{p:8}}>
                <Typography align="center"variant="h6">No hay Restaurantes para mostrar</Typography>
                </Paper>)}

                </Container>);
}

export default Index;
