import {useEffect, useState} from 'react';
import {FormGroup, FormControlLabel, Switch} from '@mui/material';
import {patch} from './constants/api';

const ActiveSwitch = (props) => {
    const [value, setValue] = useState(props.defaultValue !== undefined ? props.defaultValue : true);
    const [loaded, setLoaded] = useState(false);

    const getLabel = () => {
        if(value) return 'Activa';
        return 'Anulada';
    }

    const handleChange = (event) => {
        const checked = event.target.checked;
        patch(props.uid, !checked).then(data => console.log(data))
        setValue(checked);
    }

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            console.log('loaded defaultValue:',props.defaultValue);
            setValue(props.defaultValue);
        }
    }, [loaded, props]);

    return (<FormGroup>
        <FormControlLabel control={<Switch key={"bi-raw-switch-"+props.uid} color="success" checked={value} onChange={(e) => handleChange(e)} />} label={getLabel()} />
        </FormGroup>);
}

export default ActiveSwitch;
