import {ListItem, ListItemText, Typography} from '@mui/material';
import {weekDaysSpanish} from './constants/text';

const SettingDaysRangeDay = (props) => {
    const available = props.available;
    const index = props.index;
    return (<>
        <ListItem>
            <ListItemText primary={weekDaysSpanish[index]} secondary={available ? (<Typography variant="body2" color="success.main">Disponible</Typography>) : (<Typography variant="body2" color="error.main">No disponible</Typography>)} />
        </ListItem>
        </>);
}

export default SettingDaysRangeDay;
