import {Grid, Paper, Box, Stack, Divider, List, ListItem, ListItemText, IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate} from 'react-router-dom';

const IndexItem = (props) => {
    const data = props.data;
    const navigate = useNavigate();
    return (
        <Grid item xs={12} lg={3}>
            <Paper>
                <Stack direction="row">
                    <List sx={{width:'40%'}}>
                        <ListItem>
                            <ListItemText primary="Mesa" secondary={data.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Cupos" secondary={data.slots} />
                        </ListItem>
                    </List>
                    <Divider orientation="vertical" flexItem />
                    <List>
                        <ListItem>
                            <ListItemText primary="Sector" secondary={data.zone_name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Restaurante" secondary={data.shop_name} />
                        </ListItem>
                    </List>
                </Stack>
                <Box>
                    <Divider />
                    <Stack direction="row" spacing={2} display="flex" justifyContent="space-between" sx={{p:2}}>
                        <IconButton onClick={() => navigate('remove', {state: {uid: data.uid}})} aria-label="Borrar" size="large">
                            <DeleteIcon />
                        </IconButton>

                        <IconButton onClick={() => navigate('edit', {state: {uid: data.uid}})} aria-label="Editar" size="large">
                            <EditIcon />
                        </IconButton>
                    </Stack>
                </Box>
            </Paper>
            </Grid>);
}
export default IndexItem;
