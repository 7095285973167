import {useState, useEffect} from 'react';
import {InputLabel, MenuItem, FormControl } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {useNavigate, useParams} from 'react-router-dom';

const YearSelect = (props) => {
    const [loaded, setLoaded] = useState(false);
    const {yearURL} = useParams();
    const [year, setYear] = useState(yearURL !== undefined ? parseInt(yearURL) : new Date().getFullYear());

    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent) => {
        const newValue = parseInt(event.target.value);
        setYear(newValue);
        props.setYear(newValue);
        navigate('/reporte/'+newValue+'/'+props.month);
        props.setReloaded(false);
    };

    const yearIndexes = [...Array(3).keys()];

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded, year, yearURL]);

    return (<>{year !== undefined && props.month !== undefined && (<FormControl sx={{ width: 150}}>
        <InputLabel id="year-select-label">Año</InputLabel>
        <Select
        labelId="year-select-label"
        id="year-select"
        value={year}
        label="Año"
        onChange={handleChange}>
        {yearIndexes.map((yearIndex, index) => {
        var currentYear = new Date().getFullYear() - 1;
        return <MenuItem key={"year-select-"+index} value={currentYear + yearIndex}>{currentYear + yearIndex}</MenuItem>;
        })}
    </Select>
    </FormControl>)}</>);
}

export default YearSelect;
