import {createSearchParams} from 'react-router-dom';

export const range = (n) => {
    const offset = 8;
    const values = [...Array(n).keys()];
    return values.map((v) => offset+v);
}

export const minuteFormat = (_minute) => {
    const minuteFormatInstance = new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 2,
    });
    return minuteFormatInstance.format(_minute);
}
export const getFullBookingURL = (_slug, _year, _month, _day, _weekday, _hour, _minute) => {
    const yearInt = parseInt(_year);
    const monthInt = parseInt(_month);
    const dayInt = parseInt(_day);
    const weekdayInt = parseInt(_weekday);
    const hourInt = parseInt(_hour);
    const minuteInt = parseInt(_minute);
    return '/'+_slug+'/'+yearInt+'/'+monthInt+'/'+dayInt+'/'+weekdayInt+'/'+hourInt+'/'+minuteInt;
}
export const getNextSearchParams = (_slug, _year, _month, _day, _weekday, _hour, _minute) => {
    return createSearchParams({'next': getFullBookingURL(_slug, _year, _month, _day, _weekday, _hour, _minute)}).toString();
}

export const getParamsFromSearchParams = (searchParams) => {
    const next = searchParams.get('next');
    const [_slug, _year, _month, _day, _weekday, _hour, _minute] = next.split('/').slice(1);
    const yearInt = parseInt(_year);
    const monthInt = parseInt(_month);
    const dayInt = parseInt(_day);
    const weekdayInt = parseInt(_weekday);
    const hourInt = parseInt(_hour);
    const minuteInt = parseInt(_minute);
    return [_slug, yearInt, monthInt, dayInt, weekdayInt, hourInt, minuteInt];
}
