import {Grid, Paper, Typography} from '@mui/material';

const ZoneHeader = (props) => {
    const title = props.title;
    return (<Grid item xs={12}><Paper sx={{p: 2, mt: (props.first)?0:2, mb:2, background: 'linear-gradient(to right top, #00d4ff, #0a1172)', color: 'common.white'}}>
            <Typography align="right" variant="h5">{title}</Typography>
            </Paper></Grid>);
}

export default ZoneHeader;
