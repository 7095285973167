import {useState, useEffect} from 'react';
import {retrieveBookings} from './constants/api';
import {Container, Box, Grid, Stack, Typography, Paper} from '@mui/material';
import BookingIndexItem from './IndexItem';
import BookingDataHeader from './DataHeader';
import BookingMonthDataHeader from './MonthDataHeader';
import BookingPagination from './Pagination';
import Loader from './Loader';
import {useOutletContext} from 'react-router-dom';
import ActiveSwitch from './ActiveSwitch';

const BookingIndex = () => {
    const [loaded, setLoaded] = useState(false);
    const [bookings, setBookings] = useState(undefined);
    const [pagination, setPagination] = useState(undefined);
    const [page, setPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const {shopUID} = useOutletContext();
    const [shopUIDPrev, setShopUIDPrev] = useState(shopUID);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveBookings(shopUID, page).then((data) => {
                setBookings(data.results);
                setPagination({'count': data.count, 'pages': data.pages, 'next': data.next, 'previous': data.previous});
            });
        } 
        if(loaded && shopUID !== shopUIDPrev) {
            setShopUIDPrev(shopUID);
            retrieveBookings(shopUID, 1).then((data) => {
                setBookings(data.results);
                setPagination({'count': data.count, 'pages': data.pages, 'next': data.next, 'previous': data.previous});
                setPage(1);
            });
        }
        if(prevPage !== page) {
            setPrevPage(page);
            retrieveBookings(shopUID, page).then((data) => {
                setBookings(data.results);
                setPagination({'count': data.count, 'pages': data.pages, 'next': data.next, 'previous': data.previous});
            });
        }
    }, [loaded, bookings, shopUID, shopUIDPrev, page, prevPage]);

    return (<Container key={"bic-page-"+page}>
        {(bookings !== undefined && bookings != null && Object.keys(bookings).length > 0) && (<Stack>
            <BookingDataHeader pagination={pagination} />
            {Object.keys(bookings).map((booking_key, booking_key_index) => (<Grid container spacing={2} key={"bis-"+page+"-"+booking_key_index}>
                <BookingMonthDataHeader booking_key={booking_key} booking_key_index={booking_key_index} />

                {bookings[booking_key].map((booking, booking_index) => (<Grid item key={"bi-div-"+page+"-"+booking_key_index+"-"+booking_index} xs={12} lg={4}>
                    <Paper sx={{p:1}}>
                        <Box display="flex" justifyContent="flex-end" sx={{pl:2}}>
                            <ActiveSwitch key={"bi-switch-"+booking.uid} uid={booking.uid} defaultValue={!booking.nulled} />
                        </Box>
                        <BookingIndexItem key={"bi-item-"+page+"-"+booking_key_index+"-"+booking_index} data={booking} />
                    </Paper>
                    </Grid>))}
                    </Grid>))}

                    <BookingPagination setBookings={setBookings} pages={pagination.pages} setPage={setPage} page={page}/>
                    </Stack>)}
                    {bookings === undefined && (<Loader />)}
                    {bookings !== undefined && bookings !== null && Object.keys(bookings).length === 0 && (<Paper sx={{p:4}}>
                        <Typography variant="h5" align="center">No hay reservas para mostrar</Typography>
                        </Paper>)}
                        </Container>);
}
export default BookingIndex;
